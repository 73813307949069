module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://shungokusatsu.paultruong.me/graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Paul Truong dot me","short_name":"Paul Truong","start_url":"/","icon":"icon.png","background_color":"#fff","theme_color":"#fff","display":"standalone","include_favicon":false,"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"a412e7af0ed091719411c94bcc659125"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
